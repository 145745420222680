@import url(./assets/fonts/font.css);

html{
  height: 100%;
  box-sizing: border-box;
}

body {
    
  font-family: 'Product Sans';
  position: relative;
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-bottom: 6rem;
  box-sizing: inherit;
  background-color: #F5F7F7;

    
}

.container{
    display:flex;
}
main{
    width: 100%;
    overflow-x: auto;
}


.MuiListItemButton-root.Mui-selected:hover {
  background-color: rgba(113, 237, 203, 0.40) !important;
}

@media (max-width: 600px) {
  .responsiveTable tbody tr {
    border: 1px solid #A5A5AA;
    padding: .25em;
  }
}

