.character{
    background-color: #2A2A48;
    border-radius: 10px;
    max-width: 80px;
    max-height: 80px;
    border: 1px solid #23233C;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container{
    justify-content: space-between;
    width: 100%;
    padding: 0;
}

/* ADMIN */
.container-admin{
    justify-content: space-between;
    width: 100%;
    padding: 0;
}

.character-admin{
    background-color: #F1F1F1;
    border-radius: 10px;
    max-width: 80px;
    max-height: 80px;
    border: 1px solid #dee2e6;
    color: #3C4043;
    display: flex;
    justify-content: center;
    align-items: center;
}