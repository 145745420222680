.sidebar{
    color: #fff;
    min-height: 100vh;
    position: relative;
    transition: all 0.1s;
    border: 1px solid var(--Primary, #2A2A48);
    background: var(--Surface, #373956);
    z-index: 10000;
}
.logo{
    font-size: 30px;
}
.section {
    display: flex;
    align-items:center;
    justify-content: center;
    height: 100px;
}

.section-chevron{
    display: flex;
    align-items:center;
    justify-content: center;
    height: 100px;
    margin-top: 5em;
    cursor: pointer;
    
}
.div_section {
    display: flex;
    padding: 20px 15px;
    height: 100px;
}
.link{
    display: flex;
    color: #3C4043;
    padding: 1em 0;
    gap: 1em;
    text-align: left;
    transition: all 0.5s;
    text-decoration: none;
    padding-left: .5em;
    margin-top: .5em;
}
.link-responsive{
    display: flex;
    color: #fff;
    display: flex;
    color: #fff;
    padding: 10px 15px;
    gap: 15px;
    transition: all 0.5s;
    text-decoration: none;
    align-items: center;
}

.link:hover, link-responsive{
    /* background-image: url('../../assets/Group\ 476.svg'); */
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.5s;
}


.active{
    /* background-image: url('../../assets/Group\ 476.svg'); */

    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
  
}


.link_text{
    font-size: 18px;
}
.icon{
    display: flex;
    justify-content: center;
    height: 30px;
    width: 30px;
}

@media (max-width: 1300px){
    .section-chevron{
        display: none;
    }
}
