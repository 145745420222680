.apexcharts-legend-series { 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'Product Sans';
    color: #878B8E;
    margin: 2em;
    gap: .3em;
}

.apexcharts-legend-marker {
    order: 1;
    margin-left: .4em;
}

.apexcharts-legend-text {
    order: 2;

    color: #878B8E;
}

.apexcharts-legend{
    gap: 1em;
    margin: 0em .6em 0 2.5em;
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    overflow-y: hidden !important;
    /*overflow-y: hidden !important;
    overflow-x: scroll !important;
    white-space: nowrap !important;
    display: inline-flex !important;
    flex-direction: column;*/
    justify-content: center;
    align-items: center;
    
    text-align: center;    

}

.apexcharts-legend::-webkit-scrollbar {
    cursor: pointer;
    height: 8px !important;
    margin-bottom: 10px !important;
    border-radius: 1em;
    background-color:#F1F1F1;
}

.apexcharts-legend::-webkit-scrollbar-thumb {
    background: #c9c9c9 !important;
    padding: .2em !important;
    border-radius: 1em !important;
    cursor: pointer;
}